.courseCenter {
  margin-top: 62px;
  background-color: #F9FAFC;
  height: 100%;
  overflow-y: scroll;
}
.courseCenter::-webkit-scrollbar {
  width: 0 !important;
}
.banner {
  height: 300px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/loginIndexNew/banner1.png");
}
.main {
  width: 1440px;
  margin: 0 auto;
  position: relative;
  z-index: 50;
}
.clearfix:after {
  content: "";
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
.clearfix {
  zoom: 1;
}
.couserCenterTop {
  position: relative;
  width: 100%;
  background: #fff;
}
.couserCenterTop .couserCenterTopInner {
  width: 1440px;
  margin: 0 auto;
}
.couserCenterTop .couserCenterTopInner /deep/ .el-tabs__header {
  margin: 0;
}
.couserCenterTop .couserCenterTopInner /deep/ .el-tabs__item {
  height: 33px;
  line-height: 33px;
  transition: 0.5s   ;
  padding: 0 20px!important;
}
.couserCenterTop .couserCenterTopInner /deep/ .el-tabs__nav-wrap::after {
  background-color: transparent;
}
.couserCenterTop .couserCenterTopInner /deep/ .el-tabs__active-bar {
  background-color: transparent;
}
.couserCenterTop .couserCenterTopInner /deep/ .el-tabs__active-bar::after {
  display: none;
  content: '';
}
.couserCenterTop .couserCenterTopInner /deep/ .el-tabs__nav {
  white-space: unset;
}
.couserCenterTop .couserCenterTopInner /deep/ .el-tabs__nav-wrap.is-scrollable {
  padding: 0;
}
.couserCenterTop .couserCenterTopInner /deep/ .el-tabs__nav-next,
.couserCenterTop .couserCenterTopInner /deep/ .el-tabs__nav-prev {
  line-height: 73px;
  display: none;
}
.couserCenterTop .couserCenterTopInner .couserCenterTopLeft {
  margin-left: -20px;
  padding: 20px 0;
  float: left;
  width: calc(100% - 360px);
}
.couserCenterTop .couserCenterTopInner .couserCenterTopLeft /deep/ .el-radio-button__inner {
  background-color: transparent;
  border: transparent;
  box-shadow: none;
}
.couserCenterTop .couserCenterTopInner .couserCenterTopLeft /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: transparent;
  border: transparent;
  color: #2878FF;
}
.couserCenterTop .couserCenterTopInner .couserCenterTopLeft /deep/ .el-radio-button__inner {
  padding: 0 20px!important;
}
.couserCenterTop .couserCenterTopInner .couserCenterTopLeft /deep/ .el-tabs__item:hover {
  color: #2878FF;
}
.couserCenterTop .couserCenterTopInner .couserCenterTopLeft /deep/ .el-tabs__item.is-active {
  color: #2878FF;
}
.couserCenterTop .couserCenterTopInner .couserCenterTopLeft /deep/ .el-radio-button__inner:hover {
  color: #2878FF;
}
.couserCenterTop .couserCenterTopInner .couserCenterTopRight {
  margin-top: 16px;
  position: relative;
  float: right;
  width: 310px;
  display: flex;
  background-color: #F9FAFC;
  border-radius: 8px;
}
.couserCenterTop .couserCenterTopInner .couserCenterTopRight /deep/ .el-input__inner {
  height: 42px;
  line-height: 42px;
  border: 0;
  background-color: transparent;
}
.couserCenterTop .couserCenterTopInner .couserCenterTopRight /deep/ .el-input__icon {
  line-height: 42px;
}
.couserCenterTop .couserCenterTopInner .couserCenterTopRight /deep/ .el-button--primary {
  padding: 0 17px;
  width: 64px;
  height: 42px;
  background-color: #2878FF;
}
.couserCenterBottom .couserCenterTopInner {
  width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 15px 0;
}
.couserCenterBottom .couserCenterTopInner p {
  font-size: 14px;
  line-height: 32px;
  padding-right: 40px;
  cursor: pointer;
}
.couserCenterBottom .couserCenterTopInner p.active {
  color: #2878FF;
}
 /deep/ .el-select-dropdown__item.selected {
  color: #2878FF;
}
.courseBigBox {
  background: #f9f9f9;
  padding: 0 0 30px;
}
.courseBigBox /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2878FF;
}
.courseBigBox /deep/ .el-range-editor.is-active,
.courseBigBox /deep/ .el-range-editor.is-active:hover,
.courseBigBox /deep/ .el-select .el-input.is-focus .el-input__inner {
  border-color: #2878FF;
}
.courseBigBox .courseCenterBox {
  width: 1440px;
  margin: 12px auto 0;
}
.courseBigBox .courseCenterBox .courseBox {
  display: flex;
  flex-wrap: wrap;
}
.courseBigBox .courseCenterBox .courseBox > div {
  border-radius: 8px;
  width: 272px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 30px;
  background: #fff;
  transition: 0.5s;
}
.courseBigBox .courseCenterBox .courseBox > div:nth-child(5n) {
  margin-right: 0;
}
.courseBigBox .courseCenterBox .courseBox > div:hover {
  cursor: pointer;
  box-shadow: 0px 0px 30px 1px rgba(40, 120, 255, 0.1);
}
.courseBigBox .courseCenterBox .courseBox > div .courseDetailBtn {
  padding: 0 20px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.courseBigBox .courseCenterBox .courseBox > div .courseDetailBtn span {
  color: #999;
}
.courseBigBox .courseCenterBox .courseBox > div .courseDetailBtn button {
  background: #fff;
  color: #5393ff;
  border-color: #5393ff;
}
.courseBigBox .courseCenterBox .courseBox img {
  width: 100%;
  border-radius: 8px 8px 0 0;
  height: 153px;
}
.courseBigBox .courseCenterBox .courseBox p {
  text-align: left;
  color: #333;
  font-size: 16px;
  padding: 16px 20px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.4;
}
.courseBigBox .courseCenterBox .courseBox span {
  font-size: .8rem;
}
@media (max-width: 1500px) {
  .main {
    width: 1200px;
  }
  .banner {
    height: 250px;
  }
  .couserCenterTop .couserCenterTopInner {
    width: 1200px;
  }
  .couserCenterBottom .couserCenterTopInner {
    width: 1200px;
  }
  .courseBigBox .courseCenterBox {
    width: 1200px;
  }
  .courseBigBox .courseCenterBox .courseBox > div {
    width: 224px;
  }
  .courseBigBox .courseCenterBox .courseBox img {
    height: 126px;
  }
}
