
















































































































































































































































































































































































































.courseCenter{
  margin-top: 62px;
  background-color: #F9FAFC;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar { width: 0 !important }
}

.banner{
  height: 300px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/loginIndexNew/banner1.png");
}
.main{
  width: 1440px;
  margin: 0 auto;
  position: relative;
  z-index: 50;
  //background: #000;
}
.clearfix:after{
  content:"";//设置内容为空
  height:0;//高度为0
  line-height:0;//行高为0
  display:block;//将文本转为块级元素
  visibility:hidden;//将元素隐藏
  clear:both;//清除浮动
}
.clearfix{
  zoom:1;//为了兼容IE
}
.couserCenterTop{
  position: relative;
  width: 100%;
  background: #fff;
  //&:before{
  //  content: '';
  //  position: absolute;
  //
  //  width: 100%;
  //  height: 73px;
  //  background-color: #fff;
  //}
  .couserCenterTopInner{
    width: 1440px;
    margin: 0 auto;
    /deep/.el-tabs__header{
      margin: 0;
    }
    /deep/.el-tabs__item{
      height: 33px;
      line-height: 33px;
      transition: 0.5s   ;
      padding: 0 20px!important;
    }
    /deep/.el-tabs__nav-wrap::after{
      background-color: transparent;
    }
    /deep/.el-tabs__active-bar{
      background-color: transparent;
      &::after{
        display: none;
        content: '';
        //position: absolute;
        //bottom: 0;
        //left: 50%;
        //transform: translateX(-60%);
        //width: 60%;
        //height: 2px;
        //background-color: #2878FF;
      }
    }
    /deep/.el-tabs__nav{
      white-space: unset;
    }
    /deep/.el-tabs__nav-wrap.is-scrollable{
      padding: 0;
    }
    /deep/.el-tabs__nav-next, /deep/.el-tabs__nav-prev{
      line-height: 73px;
      display: none;
    }
    .couserCenterTopLeft{
      margin-left: -20px;
      padding: 20px 0;
      float: left;
      width: calc(100% - 360px);
      /deep/.el-radio-button__inner{
        background-color: transparent;
        border: transparent;
        box-shadow: none;
      }
      /deep/.el-radio-button__orig-radio:checked+.el-radio-button__inner{
        background-color: transparent;
        border: transparent;
        color: #2878FF;
      }
      /deep/.el-radio-button__inner{
        padding: 0 20px!important;
        //padding-left: 0;
        //padding-right: 40px;
      }
      /deep/.el-tabs__item:hover{
        color: #2878FF;
      }
      /deep/.el-tabs__item.is-active{
        color: #2878FF;
      }
      /deep/.el-radio-button__inner:hover{
        color: #2878FF;
      }
    }
    .couserCenterTopRight{
      margin-top: 16px;
      position: relative;
      float: right;
      width: 310px;
      display: flex;
      background-color: #F9FAFC;
      //background-color: #000;
      border-radius: 8px;
      /deep/.el-input__inner{
        height: 42px;
        line-height: 42px;
        border: 0;
        background-color: transparent;
      }
      /deep/.el-input__icon{
        line-height: 42px;
      }
      /deep/.el-button--primary{
        padding: 0 17px;
        width: 64px;
        height: 42px;
        background-color: #2878FF;
      }
    }
  }
}
.couserCenterBottom{
  .couserCenterTopInner{
    width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 15px 0;
    p{
      font-size: 14px;
      line-height: 32px;
      padding-right: 40px;
      cursor: pointer;
      &.active{
        color: #2878FF;
      }
    }

  }
}
/deep/.el-select-dropdown__item.selected{
  color: #2878FF;
}
.courseBigBox {
  background: #f9f9f9;
  padding: 0 0 30px;
  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #2878FF;
  }
  /deep/.el-range-editor.is-active, /deep/.el-range-editor.is-active:hover, /deep/.el-select .el-input.is-focus .el-input__inner{
    border-color: #2878FF;
  }

  .courseCenterBox {
    width: 1440px;
    margin: 12px auto 0;

    .courseBox {
      display: flex;
      flex-wrap: wrap;
      > div {
        border-radius: 8px;
        width: 272px;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        margin-bottom: 30px;
        background: #fff;
        transition: 0.5s;
        &:nth-child(5n){
          margin-right: 0;
        }
        &:hover {
          cursor: pointer;
          box-shadow: 0px 0px 30px 1px rgba(40,120,255,0.1);
        }
        .courseDetailBtn {
          padding: 0 20px 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            color: #999;
          }
          button {
            background: #fff;
            color: #5393ff;
            border-color: #5393ff;
          }
        }
      }
      img {
        width: 100%;
        border-radius: 8px 8px 0 0;
        height: 153px;
      }
      p {
        text-align: left;
        color: #333;
        font-size: 16px;
        padding: 16px 20px 0;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        line-height: 1.4;
      }
      span {
        font-size: .8rem;
      }
    }
  }
}
@media (max-width: 1500px) {
  .main{
    width: 1200px;
  }
  .banner{
    height: 250px;
  }
  .couserCenterTop .couserCenterTopInner{
    width: 1200px;
  }
  .couserCenterBottom .couserCenterTopInner{
    width: 1200px;
  }
  .courseBigBox .courseCenterBox{
    width: 1200px;
  }
  .courseBigBox .courseCenterBox .courseBox > div {
    width: 224px;
  }
  .courseBigBox .courseCenterBox .courseBox img{
    height: 126px;
  }
}



